export default {
    english: {
        navbarLinks: [
            { bloc: "about", name: "About Us" },
            { bloc: "services", name: "Our Services" },
            { bloc: "process", name: "Our Process" },
            { bloc: "contact", name: "Contact Us" },
            { bloc: "mission", name: "Our Mission" }
        ],
        hero: {
            title: "Resources tomorrow, recycled today",
            text: "We take recyclable waste through the stages of processing, refining, and reuse."
        },
        services: {
            title: "Our Services",
            text: "Recupal is a leading trading company in the Caribbean, specializing in the recovery and processing of recyclable waste materials. Since 1994, we have built an extensive network and deep expertise that ensures maximum resource recovery for a vast range of materials, including electronic waste, scrap metal, batteries, and oil refinery by-products.",
            list: [
                {
                    title: "Electronic Waste",
                    paragraph: "Cell phones, laptops, desktops, tablets, fans, audio/video equipment, routers, telephones, air conditioners, cameras.",
                    image: "service1.png"
                },
                {
                    title: "Non-Ferrous Metal Scrap",
                    paragraph: "Copper wire, insulated electric cable, aluminum panels, aluminum car rims, nickel, zinc, brass, bronze, lead, tin.",
                    image: "service2.png"
                },
                {
                    title: "Ferrous Metal Scrap",
                    paragraph: "Steel and stainless steel pipes, rods, bearings, machinery components, and  household appliances.",
                    image: "service3.png"
                },
                {
                    title: "Vehicle Batteries",
                    paragraph: "Flooded and sealed (AGM/Gel) lead-acid batteries used in cars, motorcycles, trucks, and marine vehicles.",
                    image: "service5.png"
                },
                {
                    title: "Catalytic Converters",
                    paragraph: "Automotive catalytic converters (muffler system), exhaust system converters, emission control catalysts.",
                    image: "service6.png"
                },
                {
                    title: "Oil Refining Waste",
                    paragraph: "Metal-containing refinery waste and by-products such as spent catalysts, fly ash, vanadium and nickel slag.",
                    image: "service4.png"
                },
            ]
        },
        partners: {
            title: "PARTNERS & CLIENTS",
            list: [
                "partner1.png",
                "partner2.png",
                "partner5.png",
                "partner4.png",
                "partner3.png"
            ]
        },
        proccesses: {
            title: "Our Process",
            text: "Our processing center located in Willemstad, Curacao, has been operating for over 30-years. We purchase and process recyclables from a diverse range of local and regional clients, including households, public and private sector companies. We offer convenient in-person drop-offs, or on-site collection services for large volume transactions.",
            list: [
                {
                    icon: "sprint",
                    subtitle: "STEP 1",
                    title: "Bring your things",
                    paragraph: "Bring your recyclables to our processing center located in Zeelandia, at Kaya Jacob Posner #14, Willemstad, Curacao."
                },
                {
                    icon: "price_check",
                    subtitle: "STEP 2",
                    title: "Get a quotation",
                    paragraph: "We weigh your recyclables in front of you, and provide a free quotation based on current market prices."
                },
                {
                    icon: "payments",
                    subtitle: "STEP 3",
                    title: "Get paid",
                    paragraph: "Accept our quotation and receive immediate payment. In-person transactions are settled in cash."
                }
            ]
        },
        contacts: {
            title: "Contact Us",
            list: [
                {
                    icon: "pin_drop",
                    subtitle: "VISIT",
                    title: "Kaya Jacob Posner #14\nWillemstad, Curacao",
                    paragraph: "Mon-Sat, 09:00-17:00\n* Closed on public holidays"
                },
                {
                    icon: "call",
                    subtitle: "CALL",
                    title: "+5999 461 7427",
                    paragraph: "Mon-Sat, 09:00-17:00"
                },
                {
                    icon: "mail",
                    subtitle: "EMAIL",
                    title: "sales@recupal.net",
                    paragraph: "Email contact for media, partnerships, and new business inquiries only"
                }
            ]
        },
        missions: {
            title: "Our Mission",
            text: "We are dedicated to advancing the United Nations’ Sustainable Development Goals. By supporting Goals 12 (Responsible Consumption), 15 (Life on Land), and 11 (Sustainable Cities), we promote a cleaner, more sustainable society in Curacao and beyond.",
            list: [
                {
                    title: "Recover scarce metals",
                    paragraph: "Circuit boards and batteries contain scarce metals such as gold, platinum, and lithium, which can be recovered, refined, and prepared for reuse.",
                    image: "mission1.png"
                },
                {
                    title: "Prevent soil contamination",
                    paragraph: "Recycling prevents the improper disposal of metals, electronics, and batteries that may leach toxic chemicals like lead, cadmium, and mercury into the soil.",
                    image: "mission2.png"
                },
                {
                    title: "Minimize landfill waste",
                    paragraph: "Landfills are unsustainable and costly long-term. Recycling reduces landfill use by processing materials into new products, preventing waste accumulation. ",
                    image: "mission3.png"
                }
            ]
        }
    },
    papiamentu: {
        "navbarLinks": [
            { "bloc": "about", "name": "Tokante Nos" },
            { "bloc": "services", "name": "Nos Servisio" },
            { "bloc": "process", "name": "Nos Proseso" },
            { "bloc": "contact", "name": "Tuma Kontakto" },
            { "bloc": "mission", "name": "Nos Mishon" }
        ],
        "hero": {
            "title": "Rekursonan di mañan, resiklá awe",
            "text": "Nos ta hiba desperdisio resiklábel dor di e fasenan di prosesamentu, refinamentu i reuso."
        },
        "services": {
            "title": "Nos Servisio",
            "text": "Recupal ta un kompania di komersio lider den Karibe, spesialisá den rekuperashon i prosesamentu di materialnan di shushi resiklabel. Desde 1994, nos a konstruí un ret ekstenso i ekspertisio profundo ku ta garantisá rekuperashon máksimo di rekurso pa un rango amplio di material, inkluyendo desperdisio elektróniko, metal di shushi, bateria i subproduktonan di refineria di zeta.",
            "list": [
                {
                    "title": "Desecho Elektroniko",
                    "paragraph": "Telefòn selular, laptop, desktop, tablet, fan, ekipo di oudio/video, router, telefon, airco, kámara.",
                    "image": "service1.png"
                },
                {
                    "title": "Skrap di Metal no Feroso",
                    "paragraph": "Waya di koper, kabel di koriente isolá, panelnan di aluminio, veldnan di outo di aluminio, nikkel, zink, bròns, bròns, plomo, bleki.",
                    "image": "service2.png"
                },
                {
                    "title": "Skrap di Metal Feroso",
                    "paragraph": "Tubonan di staal i staal inoxidabel, bara, bearing, komponentenan di mashin, i aparatonan di kas.",
                    "image": "service3.png"
                },
                {
                    "title": "Bateria di Vehíkulo",
                    "paragraph": "Bateria di plomo-ásido inundá i seyá (AGM/Gel) ku ta wòrdu usá den outo, motosaikel, trùk i vehíkulonan marino.",
                    "image": "service5.png"
                },
                {
                    "title": "Konvertidó Katálitiko",
                    "paragraph": "Konvertidónan katalítiko di outo (sistema di muffler), konvertidónan di sistema di eksaus, katalisadónan di kontrol di emishon.",
                    "image": "service6.png"
                },
                {
                    "title": "Desecho di Refineria di Oleo",
                    "paragraph": "Desperdisio di refineria ku ta kontené metal i subproduktonan manera katalisadónan gastá, fly ash, vanadium i slag di nikkel.",
                    "image": "service4.png"
                }
            ]
        },
        "partners": {
            "title": "PARTNERNAN & KLIENTNAN",
            "list": [
                "partner1.png",
                "partner2.png",
                "partner5.png",
                "partner4.png",
                "partner3.png"
            ]
        },
        "proccesses": {
            "title": "Nos Proseso",
            "text": "Nos sentro di prosesamentu situá na Willemstad, Kòrsou, tin mas ku 30 aña ta funshoná. Nos ta kumpra i prosesá resiklabelnan for di un rango diverso di klientenan lokal i regional, inkluyendo kasnan, kompanianan di sektor públiko i privá. Nos ta ofresé entreganan konveniente den persona, òf servisio di rekohé na e sitio pa transakshonnan di volúmen grandi.",
            "list": [
                {
                    "icon": "sprint",
                    "subtitle": "STAP 1",
                    "title": "Trese bo kosnan",
                    "paragraph": "Trese bo kosnan resiklabel na nos sentro di prosesamentu situá na Zeelandia, Kaya Jacob Posner #14, Willemstad, Curacao."
                },
                {
                    "icon": "price_check",
                    "subtitle": "STAP 2",
                    "title": "Haña un preis",
                    "paragraph": "Nos ta pisa bo resiklabelnan bo dilanti anto duna bo un preis basá riba preisnan di merkado aktual."
                },
                {
                    "icon": "payments",
                    "subtitle": "STAP 3",
                    "title": "Haña pago",
                    "paragraph": "Aseptá nos preis i risibí pago mesora. Transakshonnan den persona ta wòrdu regla na kèsh."
                }
            ]
        },
        "contacts": {
            "title": "Tuma Kontakto",
            "list": [
                {
                    "icon": "pin_drop",
                    "subtitle": "BISHITÁ",
                    "title": "Kaya Jacob Posner #14\nWillemstad, Curacao",
                    "paragraph": "Djaluna-Djasabra, 09:00-17:00\n* Será riba dianan di fiesta públiko"
                },
                {
                    "icon": "call",
                    "subtitle": "BÈL",
                    "title": "+5999 461 7427",
                    "paragraph": "Djaluna-Djasabra, 09:00-17:00"
                },
                {
                    "icon": "mail",
                    "subtitle": "EMAIL",
                    "title": "sales@recupal.net",
                    "paragraph": "Email kontakto pa medionan di komunikashon, asosiashonnan i preguntanan nobo di negoshi so."
                }
            ]
        },
        "missions": {
            "title": "Nos Mishon",
            "text": "Nos ta dediká na avansá e Metanan di Desaroyo Sostenibel di Nashonnan Uni. Dor di sostené Metanan 12 (Konsumo Responsabel), 15 (Bida riba Tera), i 11 (Statnan Duradero), nos ta promové un sosiedat mas limpi, mas sostenibel na Kòrsou i mas leu.",
            "list": [
                {
                    "title": "Rekuperá metalnan skars",
                    "paragraph": "Plachinan di sirkuito i bateria ta kontené metalnan skars manera oro, platino i litio, ku por wòrdu rekuperá, refiná i prepará pa reuso.",
                    "image": "mission1.png"
                },
                {
                    "title": "Prevení kontaminashon di tera",
                    "paragraph": "Resiklahe ta prevení e deshasimentu inkorekto di metalnan, elektróniko i bateria ku por limpia kímikonan tóksiko manera plomo, kadmio i merkurio den e tera.",
                    "image": "mission2.png"
                },
                {
                    "title": "Minimisá desperdisio di tereno di destrukshon",
                    "paragraph": "Landfillnan ta insostenibel i kostoso a largu plaso. Resiklahe ta redusí uso di landfill dor di prosesá materialnan den produktonan nobo, preveniendo akumulamentu di shushi.",
                    "image": "mission3.png"
                }
            ]
        }
    },
    nederlands: {
        "navbarLinks": [
            { "bloc": "about", "name": "Over Ons" },
            { "bloc": "services", "name": "Onze Diensten" },
            { "bloc": "process", "name": "Ons Proces" },
            { "bloc": "contact", "name": "Neem Contact" },
            { "bloc": "mission", "name": "Onze Missie" }
        ],
        "hero": {
            "title": "Bronnen van morgen, vandaag gerecycleerd",
            "text": "We leiden recyclebaar afval door de stadia van verwerking, raffinage en hergebruik."
        },
        "services": {
            "title": "Onze Diensten",
            "text": "Recupal is een toonaangevend handelsbedrijf in het Caribisch gebied, gespecialiseerd in het terugwinnen en verwerken van recyclebare afvalstoffen. Sinds 1994 hebben we een uitgebreid netwerk en diepgaande expertise opgebouwd die zorgt voor maximale terugwinning van hulpbronnen voor een breed scala aan materialen, waaronder elektronisch afval, schrootmetaal, batterijen en bijproducten van olieraffinaderijen.",
            "list": [
                {
                    "title": "Elektronisch Afval",
                    "paragraph": "Mobiele telefoons, laptops, desktops, tablets, ventilatoren, audio-/videoapparatuur, routers, telefoons, airconditioners, camera's.",
                    "image": "service1.png"
                },
                {
                    "title": "Non-ferrometaalschroot",
                    "paragraph": "Koperdraad, geïsoleerde elektrische kabel, aluminium panelen, aluminium autovelgen, nikkel, zink, messing, brons, lood, tin.",
                    "image": "service2.png"
                },
                {
                    "title": "Ferrometaalschroot",
                    "paragraph": "Stalen en roestvrijstalen buizen, staven, lagers, machineonderdelen en huishoudelijke apparaten.",
                    "image": "service3.png"
                },
                {
                    "title": "Voertuigaccu's",
                    "paragraph": "Natte en verzegelde (AGM/Gel) loodzuuraccu's voor auto's, motoren, vrachtwagens en zeevoertuigen.",
                    "image": "service5.png"
                },
                {
                    "title": "Katalysatoren",
                    "paragraph": "Autokatalysatoren (dempersysteem), uitlaatsysteemconverters, emissiecontrolekatalysatoren.",
                    "image": "service6.png"
                },
                {
                    "title": "Afval van Olieraffinage",
                    "paragraph": "Metaalhoudend raffinaderijafval en bijproducten zoals gebruikte katalysatoren, vliegas, vanadium en nikkelslak.",
                    "image": "service4.png"
                }
            ]
        },
        "partners": {
            "title": "PARTNERS & KLANTEN",
            "list": [
                "partner1.png",
                "partner2.png",
                "partner5.png",
                "partner4.png",
                "partner3.png"
            ]
        },
        "proccesses": {
            "title": "Ons Proces",
            "text": "Ons verwerkingscentrum in Willemstad, Curaçao, is al meer dan 30 jaar actief. Wij kopen en verwerken recyclebare materialen van een breed scala aan lokale en regionale klanten, waaronder huishoudens, publieke en private sectorbedrijven. Wij bieden handige persoonlijke aflever- of ophaaldiensten op locatie voor grote transacties.",
            "list": [
                {
                    "icon": "sprint",
                    "subtitle": "STAP 1",
                    "title": "Breng uw spullen",
                    "paragraph": "Breng uw recyclebare materialen naar ons verwerkingscentrum in Zeelandia, op Kaya Jacob Posner #14, Willemstad, Curaçao."
                },
                {
                    "icon": "price_check",
                    "subtitle": "STAP 2",
                    "title": "Vraag een offerte aan",
                    "paragraph": "Wij wegen uw recyclebare materialen voor u en bieden een gratis offerte op basis van de huidige marktprijzen."
                },
                {
                    "icon": "payments",
                    "subtitle": "STAP 3",
                    "title": "Word betaald",
                    "paragraph": "Accepteer onze offerte en ontvang direct betaling. Persoonlijke transacties worden contant afgehandeld."
                }
            ]
        },
        "contacts": {
            "title": "Neem Contact",
            "list": [
                {
                    "icon": "pin_drop",
                    "subtitle": "BEZOEK",
                    "title": "Kaya Jacob Posner #14\nWillemstad, Curaçao",
                    "paragraph": "Ma-Za, 09:00-17:00\n* Gesloten op feestdagen"
                },
                {
                    "icon": "call",
                    "subtitle": "BEL",
                    "title": "+5999 461 7427",
                    "paragraph": "Ma-Za, 09:00-17:00"
                },
                {
                    "icon": "mail",
                    "subtitle": "E-MAIL",
                    "title": "sales@recupal.net",
                    "paragraph": "E-mailcontact alleen voor media, partnerschappen en nieuwe zakelijke vragen."
                }
            ]
        },
        "missions": {
            "title": "Onze Missie",
            "text": "Wij zetten ons in om de Duurzame Ontwikkelingsdoelen van de Verenigde Naties te bevorderen. Door doelen 12 (Verantwoorde consumptie), 15 (Leven op het land) en 11 (Duurzame steden) te ondersteunen, promoten we een schonere, duurzamere samenleving op Curaçao en daarbuiten.",
            "list": [
                {
                    "title": "Herstel schaarse metalen",
                    "paragraph": "Printplaten en batterijen bevatten schaarse metalen zoals goud, platina en lithium, die kunnen worden herwonnen, geraffineerd en klaargemaakt voor hergebruik.",
                    "image": "mission1.png"
                },
                {
                    "title": "Voorkom bodemverontreiniging",
                    "paragraph": "Recycling voorkomt de onjuiste verwijdering van metalen, elektronica en batterijen die giftige chemicaliën zoals lood, cadmium en kwik in de bodem kunnen laten lekken.",
                    "image": "mission2.png"
                },
                {
                    "title": "Minimaliseer stortafval",
                    "paragraph": "Stortplaatsen zijn onhoudbaar en op de lange termijn kostbaar. Recycling vermindert het gebruik van stortplaatsen door materialen te verwerken tot nieuwe producten, waardoor afvalophoping wordt voorkomen.",
                    "image": "mission3.png"
                }
            ]
        }
    },
    espanol: {
        "navbarLinks": [
            { "bloc": "about", "name": "Sobre Nosotros" },
            { "bloc": "services", "name": "Nuestros Servicios" },
            { "bloc": "process", "name": "Nuestro Proceso" },
            { "bloc": "contact", "name": "Contáctenos" },
            { "bloc": "mission", "name": "Nuestra Misión" }
        ],
        "hero": {
            "title": "Recursos de mañana, reciclados hoy",
            "text": "Llevamos los residuos reciclables a través de las etapas de procesamiento, refinación y reutilización."
        },
        "services": {
            "title": "Nuestros Servicios",
            "text": "Recupal es una empresa comercial líder en el Caribe, especializada en la recuperación y procesamiento de materiales de desechos reciclables. Desde 1994, hemos construido una red extensa y una profunda experiencia que garantiza la máxima recuperación de recursos para una amplia gama de materiales, incluidos desechos electrónicos, chatarra, baterías y subproductos de refinerías de petróleo.",
            "list": [
                {
                    "title": "Desechos Electrónicos",
                    "paragraph": "Teléfonos celulares, computadoras portátiles, computadoras de escritorio, tabletas, ventiladores, equipos de audio y video, enrutadores, teléfonos, aires acondicionados, cámaras.",
                    "image": "service1.png"
                },
                {
                    "title": "Chatarra de Metales No Ferrosos",
                    "paragraph": "Alambre de cobre, cable eléctrico aislado, paneles de aluminio, llantas de aluminio para automóviles, níquel, zinc, latón, bronce, plomo, estaño.",
                    "image": "service2.png"
                },
                {
                    "title": "Chatarra de Metales Ferrosos",
                    "paragraph": "Tuberías de acero y acero inoxidable, varillas, cojinetes, componentes de maquinaria y electrodomésticos.",
                    "image": "service3.png"
                },
                {
                    "title": "Baterías para Vehículos",
                    "paragraph": "Baterías de plomo-ácido selladas y sumergidas (AGM/Gel) que se utilizan en automóviles, motocicletas, camiones y vehículos marinos.",
                    "image": "service5.png"
                },
                {
                    "title": "Convertidores Catalíticos",
                    "paragraph": "Convertidores catalíticos automotrices (sistema de silenciador), convertidores de sistemas de escape, catalizadores de control de emisiones.",
                    "image": "service6.png"
                },
                {
                    "title": "Residuos de Refinación",
                    "paragraph": "Residuos de refinería que contienen metales y subproductos como catalizadores gastados, cenizas volantes, escoria de vanadio y níquel.",
                    "image": "service4.png"
                }
            ]
        },
        "partners": {
            "title": "SOCIOS Y CLIENTES",
            "list": [
                "partner1.png",
                "partner2.png",
                "partner5.png",
                "partner4.png",
                "partner3.png"
            ]
        },
        "proccesses": {
            "title": "Nuestro Proceso",
            "text": "Nuestro centro de procesamiento ubicado en Willemstad, Curazao, ha estado operando durante más de 30 años. Compramos y procesamos materiales reciclables de una amplia gama de clientes locales y regionales, incluidos hogares, empresas del sector público y privado. Ofrecemos entregas en persona convenientes o servicios de recolección en el lugar para transacciones de gran volumen.",
            "list": [
                {
                    "icon": "sprint",
                    "subtitle": "PASO 1",
                    "title": "Traiga sus cosas",
                    "paragraph": "Traiga sus materiales reciclables a nuestro centro de procesamiento ubicado en Zeelandia, en Kaya Jacob Posner #14, Willemstad, Curazao."
                },
                {
                    "icon": "price_check",
                    "subtitle": "PASO 2",
                    "title": "Obtenga una cotización",
                    "paragraph": "Pesamos sus materiales reciclables frente a usted y le brindamos una cotización gratuita basada en los precios actuales del mercado."
                },
                {
                    "icon": "payments",
                    "subtitle": "PASO 3",
                    "title": "Reciba el pago",
                    "paragraph": "Acepte nuestra cotización y reciba el pago inmediato. Las transacciones en persona se liquidan en efectivo."
                }
            ]
        },
        "contacts": {
            "title": "Contáctenos",
            "list": [
                {
                    "icon": "pin_drop",
                    "subtitle": "VISITE",
                    "title": "Kaya Jacob Posner #14\nWillemstad, Curazao",
                    "paragraph": "Lun-Sáb, de 09:00 a 17:00\n* Cerrado los días festivos"
                },
                {
                    "icon": "call",
                    "subtitle": "LLAME",
                    "title": "+5999 461 7427",
                    "paragraph": "Lun-Sáb, de 09:00 a 17:00"
                },
                {
                    "icon": "mail",
                    "subtitle": "E-MAIL",
                    "title": "sales@recupal.net",
                    "paragraph": "Contacto por correo electrónico solo para consultas de medios, asociaciones y nuevos negocios."
                }
            ]
        },
        "missions": {
            "title": "Nuestra Misión",
            "text": "Nos dedicamos a promover los Objetivos de Desarrollo Sostenible de las Naciones Unidas. Al apoyar los Objetivos 12 (Consumo responsable), 15 (Vida en la tierra) y 11 (Ciudades sostenibles), promovemos una sociedad más limpia y sostenible en Curazao y más allá.",
            "list": [
                {
                    "title": "Recupere metales escasos",
                    "paragraph": "Las placas de circuitos y las baterías contienen metales escasos como el oro, el platino y el litio, que se pueden recuperar, refinar y preparar para su reutilización.",
                    "image": "mission1.png"
                },
                {
                    "title": "Evite la contaminación del suelo",
                    "paragraph": "El reciclaje evita la eliminación inadecuada de metales, productos electrónicos y baterías que pueden filtrar sustancias químicas tóxicas como plomo, cadmio y mercurio en el suelo.",
                    "image": "mission2.png"
                },
                {
                    "title": "Minimice los residuos en vertederos",
                    "paragraph": "Los vertederos son insostenibles y costosos a largo plazo. El reciclaje reduce el uso de vertederos al procesar materiales para convertirlos en nuevos productos, lo que evita la acumulación de residuos.",
                    "image": "mission3.png"
                }
            ]
        }
    },
    italiano: {
        "navbarLinks": [
            { "bloc": "about", "name": "Chi Siamo" },
            { "bloc": "services", "name": "Nostri Servizi" },
            { "bloc": "process", "name": "Nostro Processo" },
            { "bloc": "contact", "name": "Contattaci" },
            { "bloc": "mission", "name": "Nostra Missione" }
        ],
        "hero": {
            "title": "Risorse di domani, riciclato oggi",
            "text": "Trattiamo i rifiuti riciclabili attraverso le fasi di lavorazione, raffinazione e riutilizzo."
        },
        "services": {
            "title": "Nostri Servizi",
            "text": "Recupal è una delle principali società commerciali nei Caraibi, specializzata nel recupero e nella lavorazione di materiali di scarto riciclabili. Dal 1994, abbiamo creato una rete estesa e una profonda competenza che garantisce il massimo recupero delle risorse per una vasta gamma di materiali, tra cui rifiuti elettronici, rottami metallici, batterie e sottoprodotti della raffinazione del petrolio.",
            "list": [
                {
                    "title": "Rifiuti Elettronici",
                    "paragraph": "Cellulari, laptop, desktop, tablet, ventole, apparecchiature audio/video, router, telefoni, condizionatori d'aria, fotocamere.",
                    "image": "service1.png"
                },
                {
                    "title": "Rottami di Metalli Non Ferrosi",
                    "paragraph": "Filo di rame, cavo elettrico isolato, pannelli di alluminio, cerchioni di automobili in alluminio, nichel, zinco, ottone, bronzo, piombo, stagno.",
                    "image": "service2.png"
                },
                {
                    "title": "Rottami di Metalli Ferrosi",
                    "paragraph": "Tubi, barre, cuscinetti, componenti di macchinari ed elettrodomestici in acciaio e acciaio inossidabile.",
                    "image": "service3.png"
                },
                {
                    "title": "Batterie per Veicoli",
                    "paragraph": "Batterie al piombo allagate e sigillate (AGM/Gel) utilizzate in auto, moto, camion e veicoli marini.",
                    "image": "service5.png"
                },
                {
                    "title": "Convertitori Catalitici",
                    "paragraph": "Convertitori catalitici per autoveicoli (sistema di marmitta), convertitori del sistema di scarico, catalizzatori per il controllo delle emissioni.",
                    "image": "service6.png"
                },
                {
                    "title": "Rifiuti di Raffinazione",
                    "paragraph": "Rifiuti di raffinazione contenenti metallo e sottoprodotti come catalizzatori esausti, ceneri volanti, scorie di vanadio e nichel.",
                    "image": "service4.png"
                }
            ]
        },
        "partners": {
            "title": "PARTNER E CLIENTI",
            "list": [
                "partner1.png",
                "partner2.png",
                "partner5.png",
                "partner4.png",
                "partner3.png"
            ]
        },
        "proccesses": {
            "title": "Nostro Processo",
            "text": "Il nostro centro di lavorazione situato a Willemstad, Curacao, è operativo da oltre 30 anni. Acquistiamo e lavoriamo materiali riciclabili da una vasta gamma di clienti locali e regionali, tra cui famiglie, aziende del settore pubblico e privato. Offriamo comodi servizi di consegna di persona o di raccolta in loco per transazioni di grandi volumi.",
            "list": [
                {
                    "icon": "sprint",
                    "subtitle": "PASSO 1",
                    "title": "Porta le tue cose",
                    "paragraph": "Porta i tuoi materiali riciclabili al nostro centro di lavorazione situato a Zeelandia, presso Kaya Jacob Posner #14, Willemstad, Curacao."
                },
                {
                    "icon": "price_check",
                    "subtitle": "PASSO 2",
                    "title": "Richiedi un preventivo",
                    "paragraph": "Pesiamo i tuoi materiali riciclabili di fronte a te e forniamo un preventivo gratuito in base ai prezzi di mercato correnti."
                },
                {
                    "icon": "payments",
                    "subtitle": "PASSO 3",
                    "title": "Ricevi il pagamento",
                    "paragraph": "Accetta il nostro preventivo e ricevi il pagamento immediato. Le transazioni di persona vengono saldate in contanti."
                }
            ]
        },
        "contacts": {
            "title": "Contattaci",
            "list": [
                {
                    "icon": "pin_drop",
                    "subtitle": "VISITA",
                    "title": "Kaya Jacob Posner #14\nWillemstad, Curacao",
                    "paragraph": "Lun-Sab, 09:00-17:00\n* Chiuso nei giorni festivi"
                },
                {
                    "icon": "call",
                    "subtitle": "CHIAMA",
                    "title": "+5999 461 7427",
                    "paragraph": "Lun-Sab, 09:00-17:00"
                },
                {
                    "icon": "mail",
                    "subtitle": "E-MAIL",
                    "title": "sales@recupal.net",
                    "paragraph": "Contatto e-mail solo per media, partnership e nuove richieste commerciali."
                }
            ]
        },
        "missions": {
            "title": "Nostra Missione",
            "text": "Ci impegniamo a promuovere gli Obiettivi di sviluppo sostenibile delle Nazioni Unite. Supportando gli Obiettivi 12 (Consumo responsabile), 15 (Vita sulla terraferma) e 11 (Città sostenibili), promuoviamo una società più pulita e sostenibile a Curacao e oltre.",
            "list": [
                {
                    "title": "Recuperare metalli scarsi",
                    "paragraph": "I circuiti stampati e le batterie contengono metalli rari come oro, platino e litio, che possono essere recuperati, raffinati e preparati per il riutilizzo.",
                    "image": "mission1.png"
                },
                {
                    "title": "Prevenire la contaminazione del suolo",
                    "paragraph": "Il riciclaggio impedisce lo smaltimento improprio di metalli, componenti elettronici e batterie che possono rilasciare sostanze chimiche tossiche come piombo, cadmio e mercurio nel suolo.",
                    "image": "mission2.png"
                },
                {
                    "title": "Ridurre al minimo i rifiuti in discarica",
                    "paragraph": "Le discariche sono insostenibili e costose a lungo termine. Il riciclaggio riduce l'uso delle discariche trasformando i materiali in nuovi prodotti, prevenendo l'accumulo di rifiuti.",
                    "image": "mission3.png"
                }
            ]
        }
    }        
}
import React, { useEffect, useRef, useState } from 'react'
import Logo from "../assets/logo";
import Cover from 'react-video-cover';
import content from "../data/content";
function Home() {
    const [language, setLanguage] = useState("english");
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const supportedLanguages = ["english", "papiamentu", "nederlands", "espanol", "italiano"];
    const languageSwitcherRef = useRef(null); // Reference to the language switcher
    const dropdownRef = useRef(null); // Reference to the dropdown list

    useEffect(() => {
        const storedLanguage = localStorage.getItem("language");

        if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
            setLanguage(storedLanguage);
        } else {
            const browserLanguage = navigator.language.slice(0, 2);

            const languageMapping = {
                en: "english",
                pap: "papiamentu",
                nl: "nederlands",
                es: "espanol",
                it: "italiano"
            };

            const detectedLanguage = languageMapping[browserLanguage] || "english";
            setLanguage(detectedLanguage);
            localStorage.setItem("language", detectedLanguage);
        }
    }, []);

    // Handle click outside dropdown and language switcher
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                languageSwitcherRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !languageSwitcherRef.current.contains(event.target)
            ) {
                setIsDropdownActive(false); // Close dropdown if click is outside
            }
        };

        if (isDropdownActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownActive]);

    const toggleDropdown = () => {
        setNavbarMenuActive(navbarMenuActive == "language" ? "none" : "language");
        setIsDropdownActive(!isDropdownActive); // Toggle dropdown visibility
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem("language", lang);
        setIsDropdownActive(false); // Hide dropdown after selecting a language
        setNavbarMenuActive("none")
    };

    const [navbarSolid, setNavbarSolid] = useState(true);
    const [navbarMenuActive, setNavbarMenuActive] = useState("none"); // menu - language - none

    const videoOptions = {
        src: process.env.PUBLIC_URL + '/assets/videos/header_background.mp4',
        autoPlay: true,
        muted: true,
        loop: true,
        playsInline: true
    };

    useEffect(() => {
        const handleScroll = () => {
            setNavbarSolid(window.scrollY === 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
            setNavbarSolid(window.scrollY === 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Reveal .section_title elements based on scroll position
    useEffect(() => {
        const sectionTitles = document.querySelectorAll(".not_revealed");

        sectionTitles.forEach((title) => {
            const rect = title.getBoundingClientRect();
            const revealPosition = window.innerHeight * 0.8; // Adjust this threshold as needed

            if (rect.top < revealPosition) {
                title.classList.remove("not_revealed");
            }
        });
    }, [scrollPosition]); // Runs whenever scrollPosition changes


    function formatParagraph(text) {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    }

    return (
        <div className="root">
            <div className={`navbar_container ${navbarSolid ? "solid" : ""}`}>
                <div className="navbar">
                    <div className="navbar_left">
                        <div className="navbar_mobile_menu material-symbols-outlined" onClick={() => setNavbarMenuActive(navbarMenuActive == "menu" ? "none" : "menu")}>menu</div>
                        <div className="navbar_logo">
                            <Logo />
                        </div>
                    </div>
                    <div className="navbar_right">
                        <div className="navbar_links">
                            {
                                content[language].navbarLinks.map((navbarLink, index) => (
                                    <a key={index} href={"#" + navbarLink.bloc}>{navbarLink.name}</a>
                                ))
                            }
                        </div>
                        <div className="language_switcher" ref={languageSwitcherRef}>
                            <div className="language_switcher_main" onClick={toggleDropdown}>
                                <div className="language_icon material-symbols-outlined">language</div>
                                <span>{language}</span>
                                <div className="language_drop_icon material-symbols-outlined">arrow_drop_down</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"navbar_mobile_list_container" + (navbarMenuActive != "none" ? " active" : "")}>
                <div className="navbar_mobile_list">
                    {
                        navbarMenuActive == "menu"
                        &&
                        content[language].navbarLinks.map((navbarLink, index) => (
                            <a onClick={() => setNavbarMenuActive("none")} key={index} href={"#" + navbarLink.bloc}>{navbarLink.name}</a>
                        ))
                    }
                    {
                        navbarMenuActive == "language"
                        &&
                        supportedLanguages.map((lang, index) => (
                            <span key={index} onClick={() => changeLanguage(lang)}>{lang}</span>
                        ))
                    }
                </div>
                <div className="navbar_mobile_background" onClick={() => setNavbarMenuActive("none")} />
            </div>
            <div className={`language_dropdown_list ${isDropdownActive ? "active" : ""}`} ref={dropdownRef}>
                {
                    supportedLanguages.map((lang, index) => (
                        <span key={index} onClick={() => changeLanguage(lang)}>{lang}</span>
                    ))
                }
            </div>
            <div className="header" id="about">
                <div className="header_background">
                    <Cover
                        videoOptions={videoOptions}
                        remeasureOnWindowResize
                    />
                </div>
                <div className="header_card">
                    <h1>{content[language].hero.title}</h1>
                    <p>{content[language].hero.text}</p>
                </div>
            </div>
            <div className="wrapper">
                <div className="section_container" id="services">
                    <div className="section_title not_revealed">
                        <h1>{content[language].services.title}</h1>
                        <p>{content[language].services.text}</p>
                    </div>

                    <div className="column_3 not_revealed">
                        {
                            content[language].services.list.map(service => (
                                <div className="column_item">
                                    <img className="column_item_image" src={process.env.PUBLIC_URL + "/assets/images/" + service.image} />
                                    <div className="column_info">
                                        <h2 className="column_item_title">{service.title}</h2>
                                        <p className="column_item_paragraph">{formatParagraph(service.paragraph)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="partners_container not_revealed">
                    <h3>{content[language].partners.title}</h3>
                    <div className="partners_list">
                        {
                            content[language].partners.list.map(partner => <div className="partner_item">
                                <img src={process.env.PUBLIC_URL + "/assets/images/" + partner} />
                            </div>)
                        }
                    </div>
                </div>
                <div className="section_container" id="process">
                    <div className="section_title not_revealed">
                        <h1>{content[language].proccesses.title}</h1>
                        <p>{content[language].proccesses.text}</p>
                    </div>
                    <div className="column_3 not_revealed">
                        {
                            content[language].proccesses.list.map(proccess => (
                                <div className="column_item">
                                    <div className="column_item_icon material-symbols-outlined">{proccess.icon}</div>
                                    <div className="column_info">
                                        <h3 className="column_item_subtitle">{proccess.subtitle}</h3>
                                        <h2 className="column_item_title">{proccess.title}</h2>
                                        <p className="column_item_paragraph">{formatParagraph(proccess.paragraph)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="section_container" id="contact">
                    <div className="section_title not_revealed">
                        <h1>{content[language].contacts.title}</h1>
                    </div>
                    <div className="column_3 not_revealed">
                        {
                            content[language].contacts.list.map(contact => (
                                <div className="column_item">
                                    <div className="column_item_icon material-symbols-outlined">{contact.icon}</div>
                                    <div className="column_info">
                                        <h3 className="column_item_subtitle">{contact.subtitle}</h3>
                                        <h2 className="column_item_title">{contact.title}</h2>
                                        <p className="column_item_paragraph">{formatParagraph(contact.paragraph)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="section_container" id="mission">
                    <div className="section_title not_revealed">
                        <h1>{content[language].missions.title}</h1>
                        <p>{content[language].missions.text}</p>
                    </div>
                    <div className="column_3 not_revealed">
                        {
                            content[language].missions.list.map(mission => (
                                <div className="column_item">
                                    <img className="column_item_image" src={process.env.PUBLIC_URL + "/assets/images/" + mission.image} />
                                    <div className="column_info">
                                        <h2 className="column_item_title">{mission.title}</h2>
                                        <p className="column_item_paragraph">{formatParagraph(mission.paragraph)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer_logo">
                    <Logo />
                </div>
                <div className="footer_info">
                    <span>Recupal N.V.</span>
                    <span>Company Registration Number 67514</span>
                    <span>All rights reserved</span>
                </div>
            </div>
        </div>
    )
}

export default Home